const config = {
  treeIndent: 20,
  breakpoints: {
    grayCurtainBreak: "(max-width: 1090px)",
    small: "(max-width: 670px)",
    xsmall: "(max-width: 420px)",
    xxsmall: "(max-width: 390px)",
    curtainBreak: "(max-width: 450px)",
  },
  curtainOutSize: 250,
  mouseInteraction: {
    doubleClickTime: 400,
    hoverAppearWait: 500,
    hoverDisappearWait: 0,
  },
  cookies: {
    cookieValidTimeRememberMe: 31536000000,
    cookieValidTimeQrUser: 2880000,
    cookieValidTimeSharedUser: 2880000,
    pollInterval: 2000,
  },
  preview: {
    acceptedFiles: ["image/png", "image/jpeg", "image/bmp"],
    popover: {
      maxHeight: 250,
      maxWidth: 250,
    },
    modal: {
      maxHeight: 400,
      maxWidth: 700,
    },
    properties: {
      maxHeight: 100,
      maxWidth: 200,
    },
  },
  subNavigations: ["Master library", "Deliveries", "All doc."],
  navigations: ["Catalogue", "Find document", "Doc. administration", "Users", "Statistics", "Configurations", "Help"],
  viewerNavigations: ["Catalogue", "Find document"],
  userNavigation: ["Users", "User groups"],
  settingsNavigation: ["Settings", "Backups", "Admin logs", "Shared links", "Error logs"],
  userTypes: ["Viewer","SuperViewer", "Admin", "SuperAdmin"],
  hoverToOpen: 700,
  autoReSearch: {
    time: 1000,
    active: false,
  },

  onlineUsers: {
    noActionTimeNotification: 15 * 60 * 1000,
    inactiveUser: 24 * 3600 * 1000,
    pollInterval: 5000,
  },
  saveNoteWaitType: 2000,
  revisionLogMaxFiles: 100,
  notificationColors: {
    success: "#0CA919",
    information: "#3366FF",
    error: "#F03040",
    warning: "#FF7300",
  },
  searchFileContentPollInter: 500,
  supportedVideoFormats: [".mp4", ".mov", ".mkv"],
  localIndexFiletype: [".xlsx", ".pdf"],
  clickableDocType: [
    ".json",
    ".txt",
    ".pdf",
    ".png",
    ".jpg",
    ".bmp",
    ".jpeg",
    ".xlsx",
    ".xls",
    ".xlsm",
    ".docx",
    ".doc",
    ".pptx",
    ".mp4",
    ".mov",
    ".mkv",
  ],
  openInNewWindowDocType: [".json", ".txt", ".jpg", ".jpeg", ".png", ".pdf", ".bmp"],
  autoPreviewFiles: [".jpg", ".jpeg", ".png", ".bmp"],
  nameOfDummyFileRev: "dummy",
  illegalNameCharacters: ["<", ">", ":", '"', "/", "\\", "|", "?", "*"],
  statisticsOptions: {
    docStat: {
      viewed: "both",
      downloaded: "both",
      "viewed or downloaded": "both",
      "file size": "listOnly",
    },
    docAdminNameIfFindDocument: "findDoc",
  },
  typeToIcon: {
    ".json": "abb/document",
    ".txt": "abb/document",
    ".pdf": null,
    ".png": "abb/image",
    ".jpg": "abb/image",
    ".jpeg": "abb/image",
    ".bmp": "abb/image",
    ".xlsx": "abb/document-excel",
    ".xls": "abb/document-excel",
    ".xlsm": "abb/document-excel",
    ".docx": "abb/document-word",
    ".doc": "abb/document-word",
    ".pptx": "abb/template",
    ".mp4": "abb/video",
    ".mov": "abb/video",
    ".mkv": "abb/video",
  },
  responsiveBehaviour: {
    revisionDissapearsFirst: false,
  },
  backup: {
    utcHour: 2,
    name: "###Periodic backup",
    checkInterval: 3600000,
  },
  issueCheck: {
    checkInterval: 3000,
    issuesToCheck: ["audit", "fileIssue", "log"],
  },
  removeDoneUploadDelay: 3000,
  cleanExpiredUsersInterval: 10000,
  getAccessFromGroupsPollInterval: 10000,
  noteStatusPollInterval: 5000,
  ignoreTrackPaths: [
    "/api/deliveryGroupsTree",
    "/api/catalogueNoteInfo",
    "/api/onlineUsers",
    "/longPoll",
    "/registerLongPoll",
    "/unregisterLongPoll",
  ],
  specialUserFilters: ["Deactivated users", "Timeout users", "License users"],
  longPollTimeoutTime: 59000,
  product: {
    name: "SWAC Documentation",
    version: "4.18.0",
  },
  enableFolderDragNDrop: true,
  mostTopCatId: "top",
  minPasswordLength: 9,
  findDoc: {
    maxDeliveryShownInPopover: 8,
  },
  leftCurtain: {
    showSearchDeliveryLimit: 12,
  },
};
module.exports.config = config;
